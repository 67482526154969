import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import SocialMedia from "../components/SocialMedia"
import SEO from "../components/seo"

const Layout = styled.div`
  padding: 100px 15px 20px 15px;
  max-width: 700px;
  margin: 0 auto;

  h1 {
    color: white;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  label {
    color: white;
  }

  input {
    max-width: 450px;
  }

  textarea {
    height: 200px;
    width: 100%;
  }

  input,
  textarea {
    border: none;
    color: white;
    padding: 15px;
    background-color: #333;
    border-radius: 4px;
    margin-top: 30px;
    box-shadow: none;

    &:focus,
    &:active {
      outline-color: #ffc66d;
    }
  }

  button {
    width: fit-content;
    padding: 15px;
    background-color: #ffc66d;
    border-radius: 4px;
    border: none;
    color: #111;
    margin-top: 30px;

    &:hover {
      filter: brightness(1.1);
      cursor: pointer;
    }
  }
`

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    height: 20px;
    width: 20px;
    margin: 0 12px 0 0;
  }

  label {
    font-size: 20px;
    color: ${props => (props.selected ? "#ffc66d" : "#999")};
  }

  label,
  input {
    transition: color 150ms;

    &:hover {
      color: #ffc66d;
      cursor: pointer;
    }
  }
`

const Error = styled.p`
  color: #ff7d7d;
  margin: 2px 0 0 0;
  font-size: 17px;

  &:before {
    content: "*";
  }
`

const ContactPage = () => {
  const [thanks, setThanks] = useState(false)
  const [name, setName] = useState({ value: "" })
  const [email, setEmail] = useState({ value: "" })
  const [message, setMessage] = useState({ value: "" })
  const [anonymous, setAnonymous] = useState({ value: false })

  const resetForm = () => {
    setName({ value: "" })
    setEmail({ value: "" })
    setMessage({ value: "" })
    setAnonymous({ value: "" })
  }

  const sendMail = e => {
    e.preventDefault()

    if (!message.value)
      setMessage({
        value: message.value,
        error: "A message is required",
      })

    if (!anonymous.value && !name.value)
      setName({
        value: name.value,
        error: "A name is required",
      })

    if (!anonymous.value && !email.value)
      setEmail({
        value: email.value,
        error: "An email is required",
      })

    if (anonymous.value) {
      if (!message.value) return
    }

    if (!anonymous.value && (!name.value || !email.value || !message.value)) {
      return
    }

    axios
      .post(
        `https://dapper-conspiracy.netlify.com/.netlify/functions/sendMail`,
        {
          name: anonymous.value ? null : name.value,
          email: anonymous.value ? null : email.value,
          message: message.value,
        }
      )
      .then(response => {
        console.log(response)
      })

    resetForm()
    setThanks(true)
  }

  return (
    <Layout>
      <SEO
        title="Home"
        keywords={[`dapper`, `conspiracy`, `the dapper conspiracy`]}
      />

      {thanks ? (
        <h1>
          Thanks for reaching out to us, we'll get back to you as soon as we
          can!
        </h1>
      ) : (
        <>
          <h1>Leave us a message</h1>
          <form>
            <CheckBoxContainer selected={anonymous.value}>
              <input
                type="checkbox"
                id="anonymous"
                name="anonymous"
                checked={anonymous.value}
                onChange={e => setAnonymous({ value: e.target.checked })}
              />
              <label htmlFor="anonymous">Leave anonymous message</label>
            </CheckBoxContainer>
            {!anonymous.value && (
              <>
                <input
                  name="name"
                  placeholder="Name"
                  value={name.value}
                  onChange={e => setName({ value: e.target.value })}
                />
                {name.error && <Error>{name.error}</Error>}
                <input
                  name="email"
                  placeholder="Email"
                  value={email.value}
                  onChange={e => setEmail({ value: e.target.value })}
                />
                {email.error && <Error>{email.error}</Error>}
              </>
            )}
            <textarea
              name="message"
              placeholder="Enter your message.."
              value={message.value}
              onChange={e => setMessage({ value: e.target.value })}
            />
            {message.error && <Error>{message.error}</Error>}
            <button onClick={sendMail}>Send Message</button>
          </form>
        </>
      )}
      <SocialMedia />
    </Layout>
  )
}

export default ContactPage
