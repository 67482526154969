import React from "react"
import styled, { css } from "styled-components"
import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"
import twitter from "../images/twitter.svg"
import youtube from "../images/youtube.svg"
import spotify from "../images/spotify.svg"

const layout = css`
  margin-top: 100px;
  color: white;

  .social-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }

  img {
    margin: 30px;
    height: 150px;
    width: 150px;

    @media only screen and (max-width: 600px) {
      height: 90px;
      width: 90px;
    }
  }
`

const SocialMedia = ({ className }) => (
  <div className={className}>
    <h2>Follow us on social media for the latest updates!</h2>
    <div className="social-container">
      <a
        href="https://www.facebook.com/thedapperconspiracy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={facebook} alt="facebook" />
      </a>

      <a
        href="https://www.instagram.com/thedapperconspiracy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instagram} alt="instagram" />
      </a>

      <a
        href="https://twitter.com/DapCon5"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={twitter} alt="twitter" />
      </a>

      <a
        href="https://www.youtube.com/user/TheDapperConspiracy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={youtube} alt="youtube" />
      </a>

      <a
        href="https://open.spotify.com/artist/1H5ukRx6i5E1IP3WsjTy8T"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={spotify} alt="spotify" />
      </a>
    </div>
  </div>
)

export default styled(SocialMedia)`
  ${layout}
`
